'use client'

import { apiPlugin, storyblokInit } from '@storyblok/react/rsc'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { components } from '@vendure/bloks'
import dot from 'dot-object'
import React from 'react'
import { IntlProvider } from 'react-intl'
import translationsEN from '../locales/en.json'

function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        // With SSR, we usually want to set some default staleTime
        // above 0 to avoid refetching immediately on the client
        staleTime: 60 * 1000,
      },
    },
  })
}

let browserQueryClient: QueryClient | undefined = undefined

function getQueryClient() {
  if (typeof window === 'undefined') {
    // Server: always make a new query client
    return makeQueryClient()
  } else {
    // Browser: make a new query client if we don't already have one
    // This is very important so we don't re-make a new client if React
    // suspends during the initial render. This may not be needed if we
    // have a suspense boundary BELOW the creation of the query client
    if (!browserQueryClient) browserQueryClient = makeQueryClient()
    return browserQueryClient
  }
}

export function AppProvider({
  children,
  params,
}: {
  children: React.ReactNode
  params: Record<string, never>
}) {
  const translations = dot.dot(translationsEN)
  const queryClient = getQueryClient()

  storyblokInit({
    accessToken: process.env.NEXT_PUBLIC_SB_PUBLIC_TOKEN,
    use: [apiPlugin],
    components,
  })

  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider
        locale={params.locale ?? 'de'}
        messages={translations}
        onError={() => {}}
      >
        {children}
      </IntlProvider>
    </QueryClientProvider>
  )
}
